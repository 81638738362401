export const environment = {
    fileid:"src/environments/environment.githubapp3stg.ts",
    "isLocal":true,
    "production": false,
    "envid": "githubapp3stg",
    "snipdatafordebug": false,
    "enablesecurestorage": true,
    "urlPrivbe": "https://apigithubapp3stg.gitrospect.com",
    "urlPublicbe": "https://apigithubapp3stg.gitrospect.com",
    "debugger_active": false,
};

//     "baseurl" : "https://stg.gitrospect.com",
// "defaultghorg": "koa-health",
// "defaulttenantforlookup": "stg",
// "urlPrivbeMw": undefined,
// "urlLoginBe": undefined
// "tenantid" : "stg",
// "defaulttenantname":"stg",

  